(function(window) {
    'use strict';

    var Yunmap = (function() {
        // 机构归属字典
        var agentType = function() {
            return {
                // 1: '第三方市场',
                // 2: '云收银自营',
                // 3: '云收银返佣商户',
                6: '华东市场',
                7: '华南市场',
                8: '华中市场',
                9: '华北市场',
                10: '东北市场',
                11: '西北市场',
                12: '西南市场',
                13: '新兴市场',
                4: '银行市场',
                5: '测试及其他'

            };
        };

        // 支付类型
        var payType = function() {
            return {
                'ALP': '支付宝',
                'CSP': 'COSTCO钱包',
                'WXP': '微信',
                'BDP': '百度钱包',
                'YLP': '银联钱包'
            };
        };

        // 商户状态
        var merStatus = function() {
            return {
                'Normal': '正常',
                'close': '关闭',
                'delete': '删除'
            }
        }

        // 支付宝商户入驻等级
        var alpLevel = function() {
            return {
                'INDIRECT_LEVEL_M0': 'M0',
                'INDIRECT_LEVEL_M1': 'M1',
                'INDIRECT_LEVEL_M2': 'M2',
                'INDIRECT_LEVEL_M3': 'M3'
            }
        }

        // 支付宝商户证件类型
        var bizLicenseType = function() {
            return {
                'NATIONAL_LEGAL': '0',
                'NATIONAL_LEGAL_MERGE': '1',
                'INST_RGST_CTF': '2'
            }
        }
        // 是否
        var judgement = function() {
            return {
                '0': '否',
                '1': '是'
            }
        }
        // 支付宝法人证件类型
        var cardType = function() {
            return {
                '1': '身份证',
                '2': '护照'
            }
        }

        // 机构类型
        var agentClass = function() {
            return {
                0: '合作机构',
                1: '受理资质机构',
                2: '渠道资质机构'
            }
        }

        // 清算模式
        var settleMode = function() {
            return {
                1: '1：清算到商户',
                2: '2：清算到机构'
            }
        }

        // 流水文件类型
        var flowFileType = function() {
            return {
                1: '渠道流水',
                2: '勾兑流水',
                4: '讯联流水',
                8: '差错流水',
                16:'渠道流水(汇总)',
                32:'勾兑流水(汇总)',
                64:'讯联流水(汇总)',
                128:'差错流水(汇总)',
                256:'流水报表添加优惠信息'
            }
        }
        var fileTypeStoreIndi = function(){
          return {
            true:'是',
            false:'否'
          }
        }
        // 处理模式
        var transMode = function() {
            return {
                9: 'NO permissions',
                1: 'ALP 1.0',
                2: 'ALP 2.0 with RSA',
                3: 'ALP 2.0 with Auth',
                4: 'ALP bank mode',
                5: 'WXP service mode',
                6: 'WXP bank mode',
                7: 'WXP normal mode',
                8: 'BDP bank mode',
                10: 'JDP bank mode',
                11: 'YLP bank mode'
            }
        }
        // 支付场景
        var scene = function() {
            return {
                'scanpay': '当面付',
                'quickpay': '快捷支付',
                'facepay': '扫脸支付',
                'miniProgPay': '小程序支付'
            }
        }

        // 账单模式
        var billMode = function() {
            return {
                0: '接口下载对账单',
                2: 'ftp下载对账单',
                1: 'sftp下载对账单',
                9: '不下载对账单'
            }
        }

        //退款控制
        var refundType = function() {
            return {
                0: '无限制',
                1: '只能当日退款',
                2: '只能隔日退款'
            }
        }

        //账户类型
        var delayType = function() {
            return {
                0: '对私',
                1: '对公'
            }
        }

        //机构返佣类型
        var rebateType = function() {
            return {
                0: '无',
                1:'交易金额',
                2:'比例分成'
            }
        }

        var feeType = function() {
            return {
                0: '机构手续费',
                1: '机构手续费=商户手续费'
            }
        }

        // 卡品牌
        var bankCardType = function() {
            return {
                0: '银联借记卡',
                1: '银联贷记卡',
                2: '银联国际卡'
            }
        }

        // 价格区间
        // 卡品牌
        var amountPoint = function() {
            return {
                0: '无',
                1: '1000元(含)以下',
                2: '1000元以上'
            }
        }

        // 参与风控枚举值
        var riskType = function() {
            return {
                true: '参与风控',
                false: '不参与风控'
            }
        }

        // 增值业务类型
        var businessType = function() {
          return {
            'D0Business': 'D0业务',
            'designatedBusicd': '指定交易类型'
          }
        }

      // 交易类型
      var busicdType = function() {
        return {
          'PURC': '下单支付',
          'PAUT': '预下单',
          'JSZF': '公众号支付',
          'WPAY': 'H5支付',
          'REFD': '退款',
          'VOID': '撤销',
          'CANC': '取消',
          'VERI': '卡券核销',
          'QYZF': '企业付款',
          'RERV': '退款退回',
          'APPP': 'APP支付',
          'MRFD': '手工退款',
          'MURF': '单边对上退款',
          'WXMP': '小程序支付',
          'FPAY': '扫脸支付',
          'FSMG': '首次支付发短信',
          'FBID': '首次支付绑定',
          'SMSG': '支付发短信',
          'QPAY': '支付',
          'QIQY': '支付查询',
          'SHAR': '分账',
          'FOOS': '分账完结',
          'ROOS': '分账回退',
          'BCPA': '被扫预授权',
          'PACO': '预授权支付',
          'WAPP': 'WAP支付'
        }
      }

        return {
            agentType: agentType,
            agentClass: agentClass,
            payType: payType,
            merStatus: merStatus,
            flowFileType: flowFileType,
            fileTypeStoreIndi:fileTypeStoreIndi,
            transMode: transMode,
            billMode: billMode,
            settleMode: settleMode,
            refundType: refundType,
            rebateType: rebateType,
            businessType: businessType,
            busicdType: busicdType,
            delayType: delayType,
            alpLevel: alpLevel,
            bizLicenseType: bizLicenseType,
            cardType: cardType,
            judgement: judgement,
            feeType: feeType,
            bankCardType: bankCardType,
            amountPoint: amountPoint,
            scene: scene,
            riskType: riskType
        }
    }());

    window.Yunmap = Yunmap;
})(window);
