(function(window) {
	var Util = (function() {
		var init = function() {};
		var toast = function(text, duration, isLocale) {
			if (isLocale) {
				var el = document.createElement('i18n-msg');
        text = el.getMsg(text);
			}
			if (!text || typeof text !== 'string' || text === '') {
				return;
			}
			if (!duration || typeof duration !== 'number' || duration <= 0) {
				return;
			}
			var toast = Polymer.Base.$$('#toast');
			if (!toast) {
				toast = document.getElementById('toast');
			}

			if (!toast) {
				window.alert(text);
				return;
			}
			toast.text = text;
			toast.duration = duration;
			toast.show();

			setTimeout(function() {
				toast.text = '';
				toast.duration = 1;
			}, duration)
		};
		var I18NMap = {
			'LOGIN.USERNAME': '用户名',
			'LOGIN.PASSWORD': '密码',
			'PASSWORDMODIFICATION.REQUIREDITEM': '必填项'
		};
		var i18nTranslate = function(msgID) {
			var el = document.createElement('i18n-msg');
			var text = el.getMsg(msgID);
			if (text) {
				return text;
			}

			// 常用的国际化比如 登录名密码等。
			if (I18NMap[msgID]) {
				return I18NMap[msgID];
			}
			console.warn('I18N Translate Error: ' + msgID);

			return '';
		};
		var showLoginDialog = function() {
			var abc = document.getElementsByTagName('paper-dialog'),
				a = null;
			for (var i = 0, l = abc.length; i < l; i++) {
				a = abc[i];
				if (typeof a.close === 'function') {
					abc[i].close();
				}
			}
			Polymer.Base.fire('open-dialog-please', '', {
				node: document.getElementById('reloginDialog')
			});
		};
		var hideLoginDialog = function() {
			Polymer.Base.fire('close-dialog-please', '', {
				node: document.getElementById('reloginDialog')
			});
		};
		var fire = function(type, detail, node) {
			// create a CustomEvent the old way for IE9/10 support
			var event = document.createEvent('CustomEvent');
			// initCustomEvent(type, bubbles, cancelable, detail)
			event.initCustomEvent(type, false, true, detail);
			// returns false when event.preventDefault() is called, true otherwise
			return node.dispatchEvent(event);
		};
		// 将后台传过来的北京时间转换成当地时间
		var _toLocaleDateTime = function(dateTime) {
			if (!dateTime || !moment(dateTime, 'YYYY-MM-DD HH:mm:ss').isValid()) {
				return dateTime;
			}
			var org = dateTime + ' +0800';
			var aft = moment(org, 'YYYY-MM-DD HH:mm:ss Z').format('YYYY-MM-DD HH:mm:ss');
			return aft;
		};
		/**
		 * [_toCSTDateTime 将本地时间转换成北京时间]
		 * @param  {[type]} dateTime [string]
		 * @return {[type]}          [string]
		 */
		var _toCSTDateTime = function(dateTime) {
			if (!dateTime || !moment(dateTime, 'YYYY-MM-DD HH:mm:ss').isValid()) {
				return dateTime;
			}
			var aft = moment(dateTime, 'YYYY-MM-DD HH:mm:ss').utcOffset(8).format('YYYY-MM-DD HH:mm:ss');
			return aft;
		};
		var query = function(obj) {
			var q = '';
			for (var k in obj) {
				if (!obj[k]) {
					continue;
				}
				var v = encodeURIComponent(obj[k]);
				q += '&' + k + '=' + v;
			}
			return q.substring(1);
		};
		var RSAEncrypt = function(plaintext) {
			var publicKey = '-----BEGIN PUBLIC KEY-----\
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDF4xejo7F1JVPU555mG6Kei8XU\
2bT+V0Y+DaxzoBChaxYGOtlkf6vCh3y6Op/3OWdZAG8W17S3w9V7Skw0PvFvqqqc\
8JLlnr9/zDKoit5X17VHX8Ky3jdl7Ll2h3MFghAbzcf0P7CRGxgpTm+lqsPQXETz\
DEBEqXeE7Q7WeseaHQIDAQAB\
-----END PUBLIC KEY-----';
			var rsa = new JSEncrypt();
			rsa.setPublicKey(publicKey);
			return rsa.encrypt(plaintext);
		};
		// 美化金额，加上逗号，把10000 转成 10,000
		var beautifyAmount = function(amount) {
			var x = amount.split('.');
			var x1 = x[0];
			var x2 = '';
			x2 = x.length > 1 ? '.' + x[1] : '';
			var rgx = /(\d+)(\d{3})/;
			while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + ',' + '$2');
			}
			return x1 + x2;
		};
		var currencyDecimal = function() {
			// 币种精度表
			return {
				'GBP': {
					key: 'GBP',
					currency: 'British Sterling',
					decimal: 2
				},
				'HKD': {
					key: 'HKD',
					currency: 'Hong Kong Dollar',
					decimal: 2
				},
				'CNY': {
					key: 'CNY',
					currency: 'Chinese RMB',
					decimal: 2
				},
				'JPY': {
					key: 'JPY',
					currency: 'Japanese Yen',
					decimal: 0
				},
				'USD': {
					key: 'USD',
					currency: 'U.S. Dollar',
					decimal: 2
				}
			};
		};
		return {
			init: init,
			fire: fire,
			toast: toast,
			query: query,
			showLoginDialog: showLoginDialog,
			hideLoginDialog: hideLoginDialog,
			toLocaleDateTime: _toLocaleDateTime,
			toCSTDateTime: _toCSTDateTime,
			RSAEncrypt: RSAEncrypt,
			beautifyAmount: beautifyAmount,
			getCurrencyDecimal: currencyDecimal,
			i18nTranslate: i18nTranslate
		};
	}());

	window.Util = Util;

	Date.prototype.format = function(format) {
		var o = {
			'M+': this.getMonth() + 1, //month
			'd+': this.getDate(), //day
			'h+': this.getHours(), //hour
			'm+': this.getMinutes(), //minute
			's+': this.getSeconds(), //second
			'q+': Math.floor((this.getMonth() + 3) / 3), //quarter
			'S': this.getMilliseconds() //millisecond
		};

		if (/(y+)/.test(format)) {
			format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
		}

		for (var k in o) {
			if (new RegExp('(' + k + ')').test(format)) {
				format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
			}
		}
		return format;
	};

})(window);
