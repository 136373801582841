// add by ann 2019-10-17,用户权限控制
(function(window) {
    var AuthControl = (function() {
        var init = function() {};
        // 权限控制配置初始化
        // 配置项目支持岗权限
        var projectSupportAuth = {
            userType: 'projectSupport',
            urls: [
                'qr.routerPolicy.operation',
                'qr.mer.viewMerCode',
                'systemMgt.updatePwd.update',               
                'qr.organization.find',
                'qr.organization.operation',
                'qr.organization.edit',
                'qr.organization.create',
                'qr.agency.find',
                'qr.chanMerEntry.saveAlipay',
                'qr.chanMerEntry.saveWx',       
                'qr.chanMerEntry.saveYl',
                'qr.bulkImport.select',
                'qr.mer.find',
                'qr.mer.export',
                'qr.mer.edit',
                'qr.mer.findRouter',    
                'qr.chanMer.find',
                'qr.chanMer.edit',
                'qr.chanMer.delete',
                'qr.routerPolicy.find',
                'qr.routerPolicy.export',
                'qr.routerPolicy.delete',
                'qr.feeScheme.find',
                'qr.feeScheme.edit',
                'qr.feeScheme.delete',
                'qr.feeScheme.create',
                'qr.publicAccountPush.find',
                'qr.publicAccountPush.edit',
                'transQuery.qr.find',
                'transQuery.qr.download',
                'transQuery.qr.queryMessage',
                'transQuery.qr.lookRouter',              
                'transQuery.cardCancel.find',
                'transQuery.cardCancel.queryMessage',
                'statisticsStatement.qrTransStatistics.find',
                'statisticsStatement.qrTransStatistics.downloadDetail',
                'statisticsStatement.qrTransStatistics.downloadSummary',
                'statisticsStatement.qrTransStatement.find',
                'statisticsStatement.qrTransStatement.download',
                'statisticsStatement.merStatisticsStatement.find',
                'statisticsStatement.merStatisticsStatement.export',
                'intest.test'         
            ]
        };
        // 配置营运中心岗权限
        var operationCenterAuth = {
            userType: 'operationCenter',
            urls: [
                'qr.routerPolicy.operation',
                'qr.mer.viewMerCode',
                'systemMgt.updatePwd.update',
                'systemMgt.appUser.resetPwd',
                'qr.organization.find',
                'qr.agency.find',
                'qr.merEntry.saveMer',
                'qr.chanMerEntry.saveAlipay',
                'qr.chanMerEntry.saveWx',
                'qr.chanMerEntry.saveYl',
                'qr.bulkImport.select',
                'qr.bulkImport.update',
                'qr.mer.find',
                'qr.mer.export',
                'qr.mer.edit',
                'qr.mer.findRouter',
                'qr.womStoreList.hyperlink',
                'qr.chanMer.find',
                'qr.chanMer.edit',
                'qr.routerPolicy.find',
                'qr.routerPolicy.export',
                'qr.routerPolicy.delete',
                'qr.feeScheme.find',
                'qr.publicAccountPush.find',
                'qr.publicAccountPush.edit',
                'transQuery.qr.find',
                'transQuery.qr.download',
                'transQuery.qr.queryMessage',
                'transQuery.qr.lookRouter',              
                'transQuery.cardCancel.find',
                'transQuery.cardCancel.queryMessage',
                'statisticsStatement.qrTransStatistics.find',
                'statisticsStatement.qrTransStatistics.downloadDetail',
                'statisticsStatement.qrTransStatistics.downloadSummary',
                'statisticsStatement.qrTransStatement.find',
                'statisticsStatement.qrTransStatement.download',
                'statisticsStatement.merStatisticsStatement.find',
                'statisticsStatement.merStatisticsStatement.export',
                'statisticsStatement.clearSiteMonitoring.find',
                'statisticsStatement.clearSiteMonitoring.stopRefresh',
                'statisticsStatement.qrProfessional.find',
                'statisticsStatement.qrProfessional.export'
            ]
        };
        // 配置营运监控岗权限
        var operationMonitoringAuth = {
            userType: 'operationMonitoring',
            urls: [
                'qr.routerPolicy.operation',
                'qr.mer.viewMerCode',
                'systemMgt.updatePwd.update',
                'systemMgt.appUser.resetPwd',
                'qr.organization.find',
                'qr.agency.find',
                'qr.bulkImport.select',
                'qr.bulkImport.update',
                'qr.mer.find',
                'qr.mer.export',
                'qr.mer.edit',
                'qr.mer.findRouter',
                'qr.womStoreList.hyperlink',
                'qr.chanMer.find',
                'qr.chanMer.edit',
                'qr.routerPolicy.find',
                'qr.routerPolicy.export',
                'qr.routerPolicy.delete',
                'transQuery.qr.find',
                'transQuery.qr.download',
                'transQuery.qr.queryMessage',
                'transQuery.qr.lookRouter',
                'transQuery.qrMonitoring.stopRefresh',
                'transQuery.qrMonitoring.queryMessage',
                'transQuery.cardCancel.find',
                'transQuery.cardCancel.queryMessage',
                'statisticsStatement.qrTransStatistics.find',
                'statisticsStatement.qrTransStatistics.downloadDetail',
                'statisticsStatement.qrTransStatistics.downloadSummary',
                'statisticsStatement.qrTransStatement.find',
                'statisticsStatement.qrTransStatement.download',
                'statisticsStatement.merStatisticsStatement.find',
                'statisticsStatement.merStatisticsStatement.export',
                'statisticsStatement.clearSiteMonitoring.find',
                'statisticsStatement.clearSiteMonitoring.stopRefresh'
            ]
        };
         // 配置研发支持岗权限
        var RDSupportAuth = {
            userType: 'RDSupport',
            urls: [
                'qr.mer.viewMerCode',
                'systemMgt.updatePwd.update',
                'qr.organization.find',
                'qr.agency.find',
                'qr.mer.find',
                'qr.mer.findRouter',
                'qr.womStoreList.hyperlink',
                'qr.chanMer.find',
                'qr.routerPolicy.find',
                'qr.publicAccountPush.find',
                'qr.publicAccountPush.edit',
                'transQuery.qr.find',
                'transQuery.qr.queryMessage',
                'transQuery.qr.lookRouter',              
                'transQuery.cardCancel.find',
                'transQuery.cardCancel.queryMessage',        
                'statisticsStatement.qrTransStatistics.find',
                'statisticsStatement.qrTransStatement.find',
                'statisticsStatement.merStatisticsStatement.find',
                'statisticsStatement.clearSiteMonitoring.find',
                'statisticsStatement.clearSiteMonitoring.stopRefresh'
            ]

        };
         // 配置基础查询岗权限
        var basicInquiryAuth = {
            userType: 'basicInquiry',
            urls: [
                'systemMgt.updatePwd.update',
                'qr.organization.find',
                'qr.agency.find',
                'qr.mer.find',
                'qr.chanMer.find',
                'qr.routerPolicy.find',
                'qr.feeScheme.find',
                'transQuery.qr.find',
                'transQuery.qr.queryMessage'
            ]
        };   
        var userTypes=[projectSupportAuth, operationCenterAuth, operationMonitoringAuth, RDSupportAuth, basicInquiryAuth];
        
        // 公共函数，供组件调用，判断权限
        var authResult = function(userType, url) {
            if (userType === 'admin') {
                return true;
            }
            var authFlag = false;
            var i;
            var j;
            for (i = 0; i < userTypes.length; i++) {
                if (userTypes[i].userType === userType){
                    for (j = 0; j < userTypes[i].urls.length; j++) {
                        if (userTypes[i].urls[j] === url) {
                            authFlag = true;
                        }
                    }                     
                }
            }
            return authFlag;
        }
        return {
            init: init,
            authResult: authResult
        };
    }());

    window.AuthControl = AuthControl;
})(window);
